.banner-dark-theme {
    margin-top: 3rem;
}

.md_py_10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.stats_3col {
    column-gap: .75rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(max-content, 1fr) 1px) minmax(max-content, 1fr);
    row-gap: 0;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 920px) {
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
      }
}

.stats_row_icon {
    height: 1.75rem;
    width: auto;
    color: #fff;

    @media (max-width: 786px) {
        height: 1.5rem;
    }

    @media (max-width: 480px) {
        height: 1.25rem;
    }
}

.banner-dark-theme .stats_row_icon {
    color: #1b1b1b;
}



.stats_row_title {
    color: #fff;
    font-size: 1.75rem;
    letter-spacing: .05rem;
    line-height: 2rem;
    font-weight: 800;
    @media (max-width: 786px) {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    @media (max-width: 480px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

.banner-dark-theme .stats_row_title {
    color: #1b1b1b;
}

.stats_row_subtitle {
    color: #595e67;
    margin-top: 0.25rem;
    line-height: 1.25rem;
    padding-left: 0;
    text-transform: uppercase;
    text-align: center;
}

.banner-dark-theme .stats_row_subtitle {
    color: #42464c;
}

.stats_divider_container {
    @media (max-width: 920px) {
        display: none !important;
    }
}

.stats_divider {
    color: #bdbdbd !important;
    background-color: #bdbdbd;
    height: 4rem !important; /* Override Bootstrap's default */
    width: 2px;
    margin: 1rem 0;

    @media (max-width: 920px) {
        display: none !important;
      }
}

.banner-dark-theme .stats_divider {
    background-color: #42464c;
}

.button_programs {
    @media (max-width: 920px) {
        grid-column: 1 / -1;
        width: 100%;
    }
}