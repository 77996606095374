.socialProof {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border: 1px solid #ffffff80;
    border-radius: 12px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    padding: 48px 32px 32px;
    display: flex;
    position: relative;

    @media (max-width: 479px) {
        flex-wrap: wrap;
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
      }
  }

  .socialProofDarkTheme {
    border: 1px solid #a292d9;
    /* background-color: #6f6e72; */
  }
  
  .socialProofLogos {
    max-height: 3rem;
    margin: 10px;
    color: #fff;
    -webkit-text-stroke-color: #fff;

    @media (max-width: 479px) {
        height: auto;
    }
  }
  
  .textBlock {
    background-color: #000;
    color: #fff;
    padding-left: 17px;
    padding-right: 17px;
    font-size: 1.5rem;
    font-weight: 500;
    position: absolute;
    text-align: center;
    top: -20px;

    @media (max-width: 479px) {
        margin-left: 11px;
        margin-right: 11px;
        font-size: 1rem;
    }

    @media (max-width: 640px) {
        font-size: 1.25rem;
    }
  }

  .socialProofDarkTheme .textBlock {
    background-color: #f8f6ff;
    border-radius: 16px;
    color: #000;
  }
  
  
  