.pmc-prg-card {
    background-color: #1c1c1c;
    border: 1px solid #5f5f5f;
    border-radius: 8px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 28px;
    display: flex;
    grid-column-gap: 13px;
    grid-row-gap: 13px;
    /* z-index: 1; */
}

.card-light-theme {
    background-color: #f0eef8;
    border: 1px solid #c3b7f0;
}

.pmc-prg-card-wrap {
    display: grid;
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-columns: 1fr;
    grid-template-rows: auto;
}

.pmc-prg-card-img {
    background-color: #e7dcfe;
    border-radius: 8px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    display: flex;
}

.pmc-text-large {
    font-size: 1.25rem;
    line-height: 1.5;
    color: white;
}

.card-light-theme .pmc-text-large {
    color: #000000;
}

@media screen and (max-width: 991px) {
    .pmc-prg-card-wrap {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }
}

@media screen and (max-width: 479px) {
    .pmc-prg-card-wrap {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        justify-content: center;
        /* align-items: flex-start;
        justify-items: center; */
    }

    .pmc-prg-card {
        max-width: 250px;
        padding: 14px;
    }

    .pmc-prg-card-img {
        width: 45px;
    }

    .pmc-text-large {
        font-size: 1rem;
        line-height: 1.5;
    }
}
