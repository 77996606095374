.accordion {
    list-style-type: none;
    /* padding-left: 10px;
    padding-right: 10px; */
    border: none;
    margin-top: 2rem;
    margin-bottom: 10px;

    @media (min-width: 767px) {
        margin-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.lightThemeAccordion {
    border: none;
}

.accordionButton {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .accordionButton {
        transition: none
    }
}

.lightThemeAccordion .accordionButton {
    color: #e0dee7;
    background-color: #e0dee7;
}

.accordionButton:not(.collapsed) {
    color: #0b0b0b;
    background-color: #191919;
    box-shadow: inset 0 -1px 0 #00000020
}

.lightThemeAccordion .accordionButton:not(.collapsed) {
    color: #e0dee7;
    background-color: #e0dee7;
    box-shadow: none;
}

.accordionButton:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg)
}

.accordionButton::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .accordionButton::after {
        transition: none
    }
}

.accordionButton:hover {
    z-index: 2
}

.accordionButton:focus {
    z-index: 3;
    border-color: #3e3e3e;
    outline: 0;
    box-shadow: 0 0 0 .25rem #39393940
}

.accordionHeader {
    margin-bottom: 0
}

.accordionItem {
    background-color: #000000;
    border: 1px solid #212121;
    margin-bottom: 10px;
}

.lightThemeAccordion .accordionItem {
    background-color: #efedf6;
    border: none;
}

.accordionItem:first-of-type {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.accordionItem:first-of-type .accordionButton {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.accordionItem:not(:first-of-type) {
    border-top: 0
}

.accordionItem:last-of-type {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordionItem:last-of-type .accordionButton.collapsed {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.accordionItem:last-of-type .accordionCollapse {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordionBody {
    padding: 1rem 0rem
}

.accordion-flush .accordionCollapse {
    border-width: 0
}

.accordion-flush .accordionItem {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordionItem:first-child {
    border-top: 0
}

.accordion-flush .accordionItem:last-child {
    border-bottom: 0
}

.accordion-flush .accordionItem .accordionButton {
    border-radius: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none
}

.accordionHeader {
    display: flex;
    align-items: center;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 5px;
  }
  
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  
  .accordionTitle {
    color: #ffffff; /* adjust the color as needed */
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 800;
  }

  .lightThemeAccordion .accordionTitle {
    color: #222222; /* adjust the color as needed */
  }
  
  
  .accordionSubtitle {
    color: #ababff;
    letter-spacing: .8px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 100%;
  }

  .lightThemeAccordion .accordionSubtitle {
    color: #3434d9; /* adjust the color as needed */
  }


  .tableOfContentsListItem {
    display: flex;
    cursor: pointer;
    align-items: center;
    transition: background-color 0.3s;
    padding: 1.5rem 1rem;

    @media (max-width: 767px) {
        padding: 1.5rem 0.75rem;
    }
  }
  
  .tableOfContentsListItem:hover {
    background-color: #374151; /* Adjust this color as needed */
  }

  .lightThemeAccordion .tableOfContentsListItem:hover {
    background-color: #e0dee7; /* Adjust this color as needed */
  }
  
  .lessonImageContainer {
    position: relative;
    flex-shrink: 0;
    width: 7rem; /* Adjust width */
    width: 112px;

    @media (min-width: 767px) {
        width: 213.33px;
    }
  }
  
  
  .lessonImage {
    margin-left: auto;
    margin-right: auto;
    max-height: 120px; /* Adjust max height */
    border-radius: 0.5rem; /* Adjust border radius */
  }
  
  .lessonDetails {
    flex-grow: 1;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .lessonDetails.mdVisible {
    padding-left: 1.5rem; /* Adjust padding for medium screens */
    padding-right: 1.5rem;
  }
  
  .lessonCaption {
    font-size: .75rem;
    letter-spacing: .03125rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: #a0aec0; /* Adjust color */
  }

  .lightThemeAccordion .lessonCaption {
    color: #464c55; /* Adjust color */
  }
  
  .lessonTitle {
    font-size: .875rem;
    line-height: 1.125rem;
    margin-top: .25rem;
    font-weight: 800;
    color: #ffffff;

    @media (min-width: 980px) {
        font-size: 1rem;
        line-height: 1.25rem;
    }

    @media (min-width: 1220px) {
        font-size: 1.125rem;
        line-height: 1.35rem;
    }
  }

  .lightThemeAccordion .lessonTitle {
    color: #1b1d21; /* Adjust color */
  }
  
  .lessonDuration {
    font-size: 0.875rem; /* Adjust font size */
    margin-top: 0.75rem;
    color: #a0aec0; /* Adjust color */
  }

  .lightThemeAccordion .lessonDuration {
    color: #464c55; /* Adjust color */
  }

  .lessonDescription {
    font-size: 0.875rem; /* Adjust font size */
    margin-top: 0.25rem;
    color: #a0aec0; /* Adjust color */

    @media (min-width: 767px) {
        width: 70%;
        margin-top: 0.5rem;
        font-size: 1rem;
    }
  }

  .lightThemeAccordion .lessonDescription {
    color: #464c55; /* Adjust color */
  }
  
  .bodySmall {
    font-size: 0.875rem; /* Adjust font size */
    margin-top: 0.75rem;
  }
  
  .hidden {
    display: none;
  }
  
  .mdVisible {
    display: block; /* Adjust display for medium screens */
  }
  
  .mx6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  
  .iconSection {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b3b8be; /* Adjust color */
  }

  .lightThemeAccordion .iconSection {
    color: #96a1b3; /* Adjust color */
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .previewLabel {
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    background-color: white;
    border-radius: .25rem;
    font-size: .625rem;
    line-height: .875rem;
    display: flex;
    align-items: center;
  }

  .lightThemeAccordion .previewLabel {
    background-color: #464c55; /* Adjust color */
    color: #fff;
  }

  .warningLabel {
    background-color: #ecd996;
  }

  .lightThemeAccordion .warningLabel {
    background-color: #ecd996;
    color: #282828;
  }

  .previewLabelContainer {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }
  
  .flex {
    display: flex;
  }
  
  .itemsCenter {
    align-items: center;
  }
  
  .justifyCenter {
    justify-content: center;
  }
  

  .courseSummaryBlock {
    background-color: #c0c0ff;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  
  .courseSummaryContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    column-gap: 42px;
    row-gap: 42px;
    align-content: stretch;

    @media (max-width: 550px) {
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            flex-flow: row;
            justify-content: space-between;
            padding: 12px 22px;
            font-family: Inter, sans-serif;
        }
  }
  
  .courseSummaryText {
    color: #000;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: 550px) {
            text-align: center;
            font-size: 14px;
    }
  }
  
  .divider {
    background-color: #00000080;
    width: 1px;
    height: 26px;
  }
  