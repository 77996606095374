.button_programs {
    color: #fff;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(96.62deg, #8217ee 26%, #e0257e);
    border-radius: 5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 4rem; // Adjust padding for responsiveness
    font-weight: 700;
    width: auto;
    cursor: pointer;
    text-decoration: none; // Remove default anchor tag underline
    transition: transform 0.3s ease-in-out;

    &:hover {
        text-decoration: none; // Ensure no underline on hover
        transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }
}

.glare-btn {
    font-size: 16px;
    color: #fff;
    background-color: #463D7C;
    border-radius: 4rem;
    height: 60px; // Adjust height to auto for content responsiveness
    padding: 0.75rem 3rem; // Adjust padding for responsiveness
    font-weight: 600;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    .glare {
        position: absolute;
        width: 45px;
        height: 200%;
        transform: rotate(25deg);
        background-color: rgba(255, 255, 255, 0.3);
        top: -50%;
        left: -120%; // Start from the left
        animation: slide 2s ease infinite; // Continuous cycle
    }

    &:hover .glare {
        animation-play-state: paused; // Pause animation on hover
    }
}

@keyframes slide {
    from {
        left: -20%;
    }
    to {
        left: 120%;
    }
}

.glare:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    background: inherit;
    right: -15px;
    top: 0;
}